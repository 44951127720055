import { API_CLIENT_ERROR_NAME } from '@client/utils/error.utils';
import * as Sentry from 'sentry-isomorphic';

import { SENTRY_IGNORE_ERRORS } from '@client/store/error-constants';

export function init(dsn, release) {
  if (window.__SKIP_SENTRY_INIT_FOR_BROWSER__) {
    return;
  }

  Sentry.init({
    dsn,
    release,
    /* Not local development, where Sentry reporting should be disabled */
    environment: 'production',
    ignoreErrors: SENTRY_IGNORE_ERRORS,
    autoSessionTracking: false,
    beforeSend(event, hint) {
      const error = hint && (hint.originalException as Error);
      const entryErrorVariations = [
        '__ENTRY__ is not defined',
        "'__ENTRY__' is not defined",
        "Can't find variable: __ENTRY__",
      ];
      /* Group "undefined __ENTRY__ errors" via fingerprinting (these occur on unsupported browsers) */
      if (
        error &&
        error.constructor === Error &&
        entryErrorVariations.includes(error.message)
      ) {
        event.fingerprint = ['undefined-entry'];
      }
      /* Group "Failed to fetch" errors via fingerprinting (occur due to client connectivity issues) */
      if (
        error &&
        error.constructor === Error &&
        error.message === 'Failed to fetch'
      ) {
        event.fingerprint = ['failed-to-fetch'];
      }
      /* Separate APIClientError errors into individual Sentry events based on the error message */
      if (error && (error as any).name === API_CLIENT_ERROR_NAME) {
        event.fingerprint = ['{{ default }}', (error as any).message];
      }
      return event;
    },
    /* Pass requestId from load balancer response header into breadcrumbs */
    beforeBreadcrumb(breadcrumb, hint) {
      try {
        const isFetch = breadcrumb.category === 'fetch';
        const isXHR = breadcrumb.category === 'xhr';

        if (isFetch) {
          const fetchHeaders = hint?.response?.headers;
          if (fetchHeaders instanceof window.Headers && breadcrumb.data) {
            breadcrumb.data['hc-request-id'] =
              fetchHeaders && fetchHeaders.get('hc-request-id');
          }
        } else if (isXHR) {
          const xhr = hint?.xhr;

          if (xhr && breadcrumb.data) {
            /* Get the raw header string */
            let headers = xhr.getAllResponseHeaders();
            /* Convert the header string into an array of individual headers */
            let arr = headers.trim().split(/[\r\n]+/);
            /* Create a map of header names to values */
            let headerMap = {};
            arr.forEach(function (line) {
              let parts = line.split(': ');
              let header = parts.shift();
              let value = parts.join(': ');
              headerMap[header] = value;
            });
            breadcrumb.data['hc-request-id'] = headerMap['hc-request-id'];
          }
        }
        return breadcrumb;
      } catch (e) {
        console.error('[consumer-web] failed to process requestId', e);
        return breadcrumb;
      }
    },
  });
}
