import * as Sentry from 'sentry-isomorphic';

type SentryEventLevel = 'info' | 'warning' | 'error';

/**
 * Extend Error to create our own error constructors with custom properties
 * @param name - name of the custom error class
 * @param customPropKeys - properties to assign to the custom error class
 */
const createNewErrorType = (name: string, customPropKeys: string[] = []) => {
  CustomError.prototype = Object.create(Error.prototype);
  CustomError.prototype.constructor = CustomError;
  CustomError.prototype.name = name;

  /**
   * The new Error constructor with custom properties assigned
   * @param message - error message
   * @param customProps - custom properties for checking in catch blocks
   */
  function CustomError(
    this: Error,
    message: string,
    customProps: { [key: string]: any }
  ) {
    this.message = message;
    this.stack = Error().stack;
    // Custom properties
    customPropKeys.forEach((key) => {
      this[key] = customProps[key];
    });
  }

  return CustomError;
};

export const API_CLIENT_ERROR_NAME = 'APIClientError';
export const APIClientError = createNewErrorType(API_CLIENT_ERROR_NAME, [
  'statusCode',
  'statusText',
  'messageRaw',
  'requestUrl',
  'requestBody',
  'responseJSON',
]);

/**
 * Report error to sentry.
 * @param errorOrMessage Required message which describes the error or an error object
 * @param info Optional data for extra debugging hints
 */
export function reportToSentry(
  /* The "error" arg in catch blocks is always of type "unknown" */
  errorOrMessage: unknown,
  info: {} = {},
  level: SentryEventLevel = 'error'
) {
  /* When deving locally, expose message intended for Sentry in console since message won't actually
   * be sent */
  if (!process.env.SENTRY && process.env.NODE_ENV !== 'test') {
    console.error('reportToSentry: ' + errorOrMessage, info);
  }
  Sentry.withScope((scope) => {
    scope.setExtras(info);
    if (typeof errorOrMessage === 'string') {
      Sentry.captureMessage(errorOrMessage, level);
    } else {
      scope.setLevel(level);
      Sentry.captureException(errorOrMessage);
    }
  });
}
